import React, { useEffect, useState } from 'react';
import { getLoggedInUser } from '../../actions/authAction';
import Uploader from '../photos/uploadPhotos';
import './createFeed.css';
import SearchModal from '../search/searchModal';
import { createPost, editPost, getAllPosts, getPost } from '../../actions/feedAction';
import { toast } from 'react-toastify';
import LoadingSpinner from '../loader';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { createActionData } from '../extra-components/actiondata';

const CreateFeeds = () => {
  // const [title, setTitle] = useState('');
  const [post, setPost] = useState({});
  const [feedMessage, setFeedMessage] = useState('');
  const [media, setMedia] = useState([]);
  const [tags, setTags] = useState([]);
  const [feedRestriction, setFeedRestriction] = useState('family');
  const [user, setUser] = useState({});
  const [graphNodeId, setGraphNodeId] = useState('');
  const [showTagModal, setShowTagModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const url = decodeURI(location.search);
  const params = new URLSearchParams(url);
  let idfromURL = params.get('postid');
  useEffect(() => {
    if (idfromURL) {
      fetchPost();
    }
  }, [idfromURL]);
  useEffect(() => {
    const user = getLoggedInUser();
    console.log('User is ', user);
    setUser(user);
    setGraphNodeId(user?.graph_node_id);
  }, []);
  const fetchPost = async () => {
    try {
      const res = await getPost(idfromURL);
      setPost(res);
      setFeedMessage(res?.data?.message);
      const mediaUrls = res?.data?.media?.map((mediaItem) => mediaItem.url) || [];
      setMedia(mediaUrls);
      setTags(res?.tags);
      // setFeedRestriction(res?.data?.feedRestriction);
    } catch (e) {
      console.log(e);
    }
  };
  // console.log('post in create feeds', post);

  const handleCreateUpdateFeed = async (event) => {
    event.preventDefault();
    if (!feedMessage.trim()) {
      toast.error('Feed Content is required');
      return;
    }
    try {
      setLoading(true);
      const createdAt = new Date();
      let postData = {
        data: {
          // title,
          message: feedMessage,
          media: media.map((image) => ({ url: image, extension: 'JPEG' })),
          createdAt,
        },
        tags,
        sentBy: user?.graph_node_id,
        senderName: user?.name,
        likes: [],
        comments: [],
        gender: user?.gender,
        gautra: user?.gautra,
        status: '',
      };
      // console.log(postData);
      if (idfromURL) {
        const action = createActionData('update', user);
        postData = { ...postData, ...action };
        const response = await editPost(idfromURL, postData);
        setLoading(false);

        // if (response?.status) {
        toast.success('Post Updated Successfully');
        // } else {
        //   toast.error(`Failed to update post, ${response.error_message}`);
        // }
      } else {
        // Set the appropriate feed restriction in postData
        if (feedRestriction === 'family') {
          postData.family_id = user?.family_id;
        } else if (feedRestriction === 'village') {
          postData.village = user?.village;
        } else if (feedRestriction === 'all') {
          postData.all = true;
        }
        const response = await createPost(postData);
        setLoading(false);
        // if (response?.status) {
        toast.success('Posted Successfully');
        // } else {
        //   toast.error(`Failed to post, ${response.error_message}`);
        // }
      }
      navigate('/feeds/showAllFeeds');
    } catch (error) {
      setLoading(false);
      console.error('Error creating post:', error);
      toast.error('An error occurred while posting');
    }
  };
  const handleAddTag = (taggedUser) => {
    if (!taggedUser) {
      return;
    }
    setTags((prevTags) => {
      const previousTagList = Array.isArray(prevTags) ? prevTags : [];
      return [...previousTagList, taggedUser];
    });
  };
  const handleRemoveTag = (indexToRemove) => {
    setTags((prevTags) => {
      const previousTagList = Array.isArray(prevTags) ? prevTags : [];
      return previousTagList.filter((_, index) => index !== indexToRemove);
    });
  };

  return (
    <div className="create-feeds-container">
      {showTagModal && (
        <SearchModal
          onClose={() => setShowTagModal(false)}
          onSelectUser={handleAddTag}
          // onSelectUser={(taggedUser) => setTags((prevTags) => [...prevTags, taggedUser])}
        />
      )}
      {idfromURL ? <h2>Update Post</h2> : <h2>Create Post</h2>}
      {loading && <LoadingSpinner message="Creating Post" />}
      <div className="create-feeds-form">
        {/* <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="form-control"
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="feedMessage">
            Feed Content: <span style={{ color: 'red' }}>*</span>
          </label>
          <textarea
            id="feedMessage"
            value={feedMessage}
            onChange={(e) => setFeedMessage(e.target.value)}
            className="form-control"
          />
        </div>
        {media.length > 0 && (
          <div className="uploaded-photos">
            <h4>Uploaded Photos</h4>
            <div className="photos-grid">
              {media.map((url, index) => (
                <img key={index} src={url} alt={`Uploaded ${index + 1}`} className="uploaded-photo" />
              ))}
            </div>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="image">Upload Images:</label>
          <Uploader graphNodeId={graphNodeId} onUploadSuccess={(url) => setMedia((prevMedia) => [...prevMedia, url])} />
        </div>
        <div className="form-group">
          <label htmlFor="tags">Tags:</label>
          <input
            type="text"
            placeholder="Search to Enter Tags"
            onClick={() => setShowTagModal(true)}
            className="form-control"
          />
        </div>
        {tags?.length > 0 && (
          <div className="selected-tags">
            {tags.map((tag, index) => (
              <span key={index} className="tag">
                {tag?.name}
                <button type="button" className="remove-tag" onClick={() => handleRemoveTag(index)}>
                  &times;
                </button>
              </span>
            ))}
          </div>
        )}
        {idfromURL ? null : (
          <div className="form-group">
            <label htmlFor="feedRestriction">Feed Restriction:</label>
            <select
              id="feedRestriction"
              value={feedRestriction}
              onChange={(e) => {
                setFeedRestriction(e.target.value);
              }}
              className="form-control"
            >
              <option value="family">Family</option>
              <option value="all">All</option>
              <option value="village">Village</option>
            </select>
          </div>
        )}

        {idfromURL ? (
          <button onClick={handleCreateUpdateFeed} className="btn btn-primary">
            Update Post
          </button>
        ) : (
          <button onClick={handleCreateUpdateFeed} className="btn btn-primary">
            Create Post
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateFeeds;
